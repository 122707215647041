import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import SingleSelect, { SingleSelectInputLabel } from "../SingleSelect";
import { CustomerMetadata, School } from "./StoriesWrapper";

interface SchoolDropdownProps {
  customerMetadata: CustomerMetadata;
  selectedSchool: School | undefined;
  selectSchool: (school: School) => void;
}

interface SingleSelectOption {
  label: string;
  value: string;
}

const SchoolDropdown: React.FC<SchoolDropdownProps> = ({
  customerMetadata,
  selectedSchool,
  selectSchool,
}) => {
  const [options, setOptions] = useState<SingleSelectOption[]>([]);

  useEffect(() => {
    // Transform the schools dictionary into the options format
    const options = Object.values(customerMetadata.schools).map(
      (school: School) => ({
        label: school.name,
        value: school.id,
      })
    );

    setOptions(options);
  }, [customerMetadata]);

  const handleSelectedSchool = (selected: string) => {
    const school: School | undefined = customerMetadata.schools[selected];
    school && selectSchool(school);
  };

  return (
    <div
      style={{
        margin: "var(--std-margin-lg) 0 var(--std-margin-sm) 0",
        width: "420px",
      }}
    >
      <SingleSelectInputLabel htmlFor="school-select">
        Select School
      </SingleSelectInputLabel>
      <SingleSelect
        id="school-select"
        selected={selectedSchool?.id}
        options={options ? options : []}
        onSelectedChange={handleSelectedSchool}
        disabled={false}
        selectOptionsText="Select school or district overall"
        isClearable={false}
      />
    </div>
  );
};

export default SchoolDropdown;
