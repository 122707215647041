import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { RAButton, RAButtonSecondary } from "../lib/button";

export type IdleStates = "active" | "idle" | "prompted";

export const KeepAliveModal = ({
  idleState,
  setIdleState,
  activate,
  logout,
}: {
  idleState: string;
  setIdleState: (idleState: IdleStates) => void;
  activate: () => {};
  logout: () => void;
}) => {
  const setActive = () => {
    setIdleState("active");
    activate();
  };
  return (
    <Dialog
      open={idleState === "prompted"}
      onClose={() => {
        setActive();
      }}
    >
      <IconButton
        sx={{ position: "absolute", top: 5, right: 5 }}
        onClick={() => {
          setActive();
        }}
        aria-label="Close idle dialog"
      >
        <CloseIcon aria-label="Close" />
      </IconButton>

      <DialogTitle>Your session is about to expire</DialogTitle>
      <DialogContent>
        Your session is about to expire. You'll be automatically signed out
        soon.
      </DialogContent>
      <DialogActions>
        <RAButtonSecondary
          onClick={() => {
            logout();
          }}
        >
          Log me out
        </RAButtonSecondary>
        <RAButton
          onClick={() => {
            setActive();
          }}
          variant="contained"
        >
          Stay signed in
        </RAButton>
      </DialogActions>
    </Dialog>
  );
};
