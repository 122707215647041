import React from "react";
import { AppNavItem } from "./WaffleMenu";
import { CustomerPanelsEntity, TileEntity } from "../../interfaces/ProfileJson";
import { Column, Panel } from "../../interfaces/TableJson";
import { Metric } from "../../interfaces/VisibilitySettingsJson";
const find = require("lodash/find");

export enum AppNavItemIds {
  STAR = "star",
  STARREADING = "starreading",
  STARREADINGSPANISH = "starreadingspanish",
  STARMATH = "starmath",
  STARMATHSPANISH = "starmathspanish",
  STAREARLYLITERACY = "starearlyliteracy",
  STAREARLYLITERACYSPANISH = "starearlyliteracyspanish",
  STARSPANISH = "starspanish",
  STARCBM = "starcbm",
  STARCBMREADING = "starcbmreading",
  STARCBMMATH = "starcbmmath",
  STARCBMLECTURA = "starcbmlectura",
  STARPHONICS = "starphonics",
  MYON = "myon",
  FRECKLE = "freckle",
  LALILO = "lalilo",
  RENU = "renu",
  AR = "ar",
  REPORTS = "reports",
  SAEBRS = "saebrs",
}

export interface Entitlement {
  applicationId: string;
  taskId: string;
}

const getMyDataNavs = () => {
  let MYDATA_NAVS_REPORTS: AppNavItem = {
    id: AppNavItemIds.REPORTS,
    displayName: "Reports",
    imagePrefix: "reports_logo",
    children: getReportsLinks(),
  };

  let MYDATA_NAVS: AppNavItem[] = [MYDATA_NAVS_REPORTS];

  return MYDATA_NAVS;
};

const getReportsLinks = () => {
  let items: AppNavItem[] = [];
  items.push({
    displayName: "Star / Assessment",
    imagePrefix: "star_logo",
    applicationId: "7771",
    children: [
      {
        displayName: "Star / Assessment",
        applicationId: "7771",
        taskId: "777101",
      },
      {
        displayName: "Administrator",
        applicationId: "7771",
        taskId: "777103",
      },
      {
        displayName: "My Created Reports",
        applicationId: "7771",
        taskId: "777104",
      },
      {
        displayName: "Data Insights",
        applicationId: "4",
        taskId: "409",
      },
      {
        displayName: "Growth Expectation",
        applicationId: "4",
        taskId: "403",
      },
      {
        displayName: "Historical Extract",
        applicationId: "4",
        taskId: "404",
      },
    ],
  });
  items.push({
    displayName: "Star CBM",
    imagePrefix: "star_logo",
    applicationId: "52",
    children: [
      {
        displayName: "Data Insights",
        applicationId: "52",
        taskId: "5201",
      },
      {
        displayName: "Caregiver Report",
        applicationId: "52",
        taskId: "5202",
      },
      {
        displayName: "Historical Extract",
        applicationId: "52",
        taskId: "5203",
      },
    ],
  });
  items.push({
    displayName: "AR",
    imagePrefix: "ar_logo",
    applicationId: "1",
    children: [
      {
        displayName: "Reports",
        applicationId: "1",
        taskId: "101",
      },
      {
        displayName: "Historical Extract",
        applicationId: "1",
        taskId: "888400",
      },
    ],
  });
  items.push({
    displayName: "myON",
    imagePrefix: "myon_logo",
    applicationId: "49",
    children: [
      {
        displayName: "Reports",
        applicationId: "49",
        taskId: "4900",
        deepLinkId: "MYON_REPORTS",
      },
    ],
  });
  items.push({
    displayName: "Custom Reports",
    applicationId: "7777",
    taskId: "777700",
  });
  items.push({
    displayName: "Data Sharing Agreements",
    applicationId: "7776",
    taskId: "777600",
  });
  return items;
};

const getArItems = () => {
  let items: AppNavItem[] = [];
  items.push({
    displayName: "Independent Reading",
    subHeader: "Set goals for books and articles",
    applicationId: "1",
    children: [
      {
        displayName: "Record Book & Goals",
        applicationId: "1",
        taskId: "105",
      },
      {
        displayName: "Reports",
        applicationId: "1",
        taskId: "101",
      },
      {
        displayName: "Historical Extract",
        applicationId: "1",
        taskId: "888400",
      },
      {
        displayName: "Manage Books & Quizzes",
        applicationId: "1",
        taskId: "102",
      },
      {
        displayName: "Share Reading Snapshots",
        applicationId: "1",
        taskId: "106",
      },
      {
        displayName: "Preferences",
        applicationId: "1",
        taskId: "103",
      },
      {
        displayName: "Resources",
        applicationId: "1",
        taskId: "104",
      },
    ],
  });

  items.push({
    displayName: "Manage Content",
    subHeader: "Books, quizzes, articles or assessments",
    applicationId: "7770",
    taskId: "777000",
  });
  items.push({
    displayName: "Reading Snapshots",
    subHeader: "Create and manage shareable snapshots",
    applicationId: "7778",
    taskId: "777800",
  });

  return items;
};

const getStarItems = () => {
  let items: AppNavItem[] = [];
  items.push({
    id: AppNavItemIds.STARREADING,
    displayName: "Star Reading",
    subHeader: "For Grades 1-12",
    applicationId: "4",
    children: [
      {
        displayName: "Manage Goals & Benchmarks",
        applicationId: "4",
        taskId: "400",
      },
      {
        displayName: "Third Grade Reading Proficiency Assessment",
        applicationId: "4",
        taskId: "408",
      },
      {
        displayName: "Record Book",
        applicationId: "4",
        taskId: "401",
      },
      {
        displayName: "Data Insights",
        applicationId: "4",
        taskId: "409",
      },
      {
        displayName: "Reports",
        applicationId: "4",
        taskId: "402",
      },
      { displayName: "Growth Expectation", applicationId: "4", taskId: "403" },
      { displayName: "Historical Extract", applicationId: "4", taskId: "404" },
      {
        displayName: "Deactivate Star Tests",
        applicationId: "4",
        taskId: "405",
      },
      {
        displayName: "Preferences",
        applicationId: "4",
        taskId: "406",
      },
      {
        displayName: "Resources",
        applicationId: "4",
        taskId: "407",
      },
    ],
  });
  items.push({
    id: AppNavItemIds.STAREARLYLITERACY,
    displayName: "Star Early Literacy",
    subHeader: "For Grades pre-K-3",
    applicationId: "7",
    children: [
      {
        displayName: "Manage Goals & Benchmarks",
        applicationId: "7",
        taskId: "700",
      },
      {
        displayName: "Record Book",
        applicationId: "7",
        taskId: "701",
      },
      {
        displayName: "Data Insights",
        applicationId: "7",
        taskId: "708",
      },
      {
        displayName: "Reports",
        applicationId: "7",
        taskId: "702",
      },
      { displayName: "Growth Expectation", applicationId: "7", taskId: "703" },
      { displayName: "Historical Extract", applicationId: "7", taskId: "704" },
      {
        displayName: "Deactivate Star Tests",
        applicationId: "7",
        taskId: "705",
      },
      {
        displayName: "Preferences",
        applicationId: "7",
        taskId: "706",
      },
      {
        displayName: "Resources",
        applicationId: "7",
        taskId: "707",
      },
    ],
  });
  items.push({
    id: AppNavItemIds.STARMATH,
    displayName: "Star Math",
    subHeader: "For Grades 1-12",
    applicationId: "5",
    children: [
      {
        displayName: "Manage Goals & Benchmarks",
        applicationId: "5",
        taskId: "500",
      },
      {
        displayName: "Record Book",
        applicationId: "5",
        taskId: "501",
      },
      {
        displayName: "Data Insights",
        applicationId: "5",
        taskId: "508",
      },
      {
        displayName: "Reports",
        applicationId: "5",
        taskId: "502",
      },
      { displayName: "Growth Expectation", applicationId: "5", taskId: "503" },
      { displayName: "Historical Extract", applicationId: "5", taskId: "504" },
      {
        displayName: "Deactivate Star Tests",
        applicationId: "5",
        taskId: "505",
      },
      {
        displayName: "Preferences",
        applicationId: "5",
        taskId: "506",
      },
      {
        displayName: "Resources",
        applicationId: "5",
        taskId: "507",
      },
    ],
  });
  items.push({
    id: AppNavItemIds.STARCBMREADING,
    displayName: "Star CBM Reading",
    subHeader: "For Grades K-6",
    applicationId: "51",
    children: [
      {
        displayName: "Record Book & Assessments",
        applicationId: "51",
        taskId: "5100",
      },
      {
        displayName: "Data Insights",
        applicationId: "51",
        taskId: "5101",
      },
      {
        displayName: "Caregiver Report",
        applicationId: "51",
        taskId: "5102",
      },
      {
        displayName: "Historical Extract",
        applicationId: "51",
        taskId: "5103",
      },
      {
        displayName: "Preferences",
        applicationId: "51",
        taskId: "5104",
      },
      {
        displayName: "Resources",
        applicationId: "51",
        taskId: "5105",
      },
    ],
  });
  items.push({
    id: AppNavItemIds.STARCBMMATH,
    displayName: "Star CBM Math",
    subHeader: "For Grades K-3",
    applicationId: "52",
    children: [
      {
        displayName: "Record Book & Assessments",
        applicationId: "52",
        taskId: "5200",
      },
      {
        displayName: "Data Insights",
        applicationId: "52",
        taskId: "5201",
      },
      {
        displayName: "Caregiver Report",
        applicationId: "52",
        taskId: "5202",
      },
      {
        displayName: "Historical Extract",
        applicationId: "52",
        taskId: "5203",
      },
      {
        displayName: "Preferences",
        applicationId: "52",
        taskId: "5204",
      },
      {
        displayName: "Resources",
        applicationId: "52",
        taskId: "5205",
      },
    ],
  });
  items.push({
    id: AppNavItemIds.STARCBMLECTURA,
    displayName: "Star CBM Lectura",
    subHeader: "For Grades K-6",
    applicationId: "66",
    children: [
      {
        displayName: "Record Book & Assessments",
        applicationId: "66",
        taskId: "6600",
      },
      {
        displayName: "Data Insights",
        applicationId: "66",
        taskId: "6601",
      },
      {
        displayName: "Caregiver Report",
        applicationId: "66",
        taskId: "6602",
      },
      {
        displayName: "Historical Extract",
        applicationId: "66",
        taskId: "6603",
      },
      {
        displayName: "Preferences",
        applicationId: "66",
        taskId: "6604",
      },
      {
        displayName: "Resources",
        applicationId: "66",
        taskId: "6605",
      },
    ],
  });
  items.push({
    id: AppNavItemIds.STARSPANISH,
    displayName: "Star Spanish",
    subHeader: "For Grades 1-12",
    applicationId: "35",
    children: [
      {
        displayName: "Manage Goals & Benchmarks",
        applicationId: "35",
        taskId: "3502",
      },
      {
        displayName: "Record Book",
        applicationId: "35",
        taskId: "3501",
      },
      {
        displayName: "Data Insights",
        applicationId: "35",
        taskId: "3503",
      },
      {
        displayName: "Reports",
        applicationId: "35",
        taskId: "3504",
      },
      {
        displayName: "Preferences",
        applicationId: "35",
        taskId: "3500",
      },
      {
        id: AppNavItemIds.STARREADINGSPANISH,
        displayName: "Reading Resources",
        applicationId: "35",
        taskId: "3505",
      },
      {
        id: AppNavItemIds.STAREARLYLITERACYSPANISH,
        displayName: "Early Literacy Resources",
        applicationId: "35",
        taskId: "3506",
      },
      {
        id: AppNavItemIds.STARMATHSPANISH,
        displayName: "Math Resources",
        applicationId: "35",
        taskId: "3507",
      },
    ],
  });
  items.push({
    displayName: "Star Preschool",
    subHeader: "Early Education and Pre-K",
    children: [
      {
        displayName: "Assessment Dashboard & Assessments",
        applicationId: "68",
        taskId: "6800",
      },
      {
        displayName: "Resources",
        applicationId: "68",
        taskId: "6801",
      },
    ],
  });
  items.push({
    displayName: "Star Custom",
    subHeader: "Tests designed for specific skills",
    applicationId: "26",
    taskId: "2600",
  });
  items.push({
    id: AppNavItemIds.STARPHONICS,
    displayName: "Star Phonics",
    subHeader: "For Grades 1-6+",
    applicationId: "69",
    taskId: "6900",
  });
  items.push({
    displayName: "Planner",
    subHeader: "Assign articles, quizzes, and assignments",
    applicationId: "34",
    taskId: "3400",
  });
  items.push({
    id: AppNavItemIds.SAEBRS,
    displayName: "SAEBRS",
    subHeader: "For Grades K-12",
    applicationId: "71",
    taskId: "2650",
  });

  return items;
};

const getAllAppNavItems = () => {
  let MYAPPS_NAVS_AR: AppNavItem = {
    id: AppNavItemIds.AR,
    displayName: "AR",
    longDisplayName: "Accelerated Reader",
    imagePrefix: "ar_logo",
    applicationId: "1",
    children: getArItems(),
  };

  let MYAPPS_NAVS_FRECKLE: AppNavItem = {
    id: AppNavItemIds.FRECKLE,
    displayName: "Freckle",
    imagePrefix: "freckle_logo",
    applicationId: "7779",
    taskId: "777900",
  };

  let MYAPPS_NAVS_LALILO: AppNavItem = {
    id: AppNavItemIds.LALILO,
    displayName: "Lalilo",
    imagePrefix: "lalilo_logo",
    applicationId: "62",
    taskId: "6200",
  };

  let MYAPPS_NAVS_MYON: AppNavItem = {
    id: AppNavItemIds.MYON,
    displayName: "myON",
    imagePrefix: "myon_logo",
    applicationId: "49",
    taskId: "4900",
  };

  let MYAPPS_NAVS_NEARPOD: AppNavItem = {
    displayName: "Nearpod",
    imagePrefix: "nearpod_logo",
    url: "https://nearpod.com/",
  };

  let MYAPPS_NAVS_SCHOOLZILLA: AppNavItem = {
    displayName: "Schoolzilla",
    imagePrefix: "schoolzilla_logo",
    applicationId: "56",
    taskId: "4851",
  };

  let MYAPPS_NAVS_STAR: AppNavItem = {
    id: AppNavItemIds.STAR,
    displayName: "Star",
    longDisplayName: "Star Assessments",
    imagePrefix: "star_logo",
    children: getStarItems(),
  };

  let MYAPPS_NAVS_RENU: AppNavItem = {
    id: AppNavItemIds.RENU,
    displayName: "Renaissance-U",
    imagePrefix: "ren_u_logo",
    applicationId: "33",
    taskId: "3301",
  };

  let MYAPPS_NAVS: AppNavItem[] = [
    // most are alphabetical:
    MYAPPS_NAVS_AR,
    MYAPPS_NAVS_FRECKLE,
    MYAPPS_NAVS_LALILO,
    MYAPPS_NAVS_MYON,
    MYAPPS_NAVS_NEARPOD,
    MYAPPS_NAVS_SCHOOLZILLA,
    MYAPPS_NAVS_STAR,
    // REN U is last
    MYAPPS_NAVS_RENU,
  ];

  return MYAPPS_NAVS;
};

const isAppTaskAvailable = (
  appNavItem: AppNavItem,
  entitlements: Entitlement[]
) => {
  return entitlements.some(
    (entitlement) =>
      !appNavItem.applicationId ||
      (!appNavItem.taskId &&
        entitlement.applicationId === appNavItem.applicationId) ||
      entitlement.taskId === appNavItem.taskId
  );
};

export const getAppNavItemsByEntitlements = (
  entitlements: Entitlement[] | null
): AppNavItem[] => {
  if (!entitlements) return [];
  let myAppsNavItems = getAllAppNavItems();
  return getAvailableTilesRecursive(myAppsNavItems, entitlements);
};

// call getAvailableTilesRecursive with all appNavItems
const getAvailableTilesRecursive = (
  appNavItems: AppNavItem[],
  entitlements: Entitlement[]
) => {
  let availableAppNavItems: AppNavItem[] = [];
  for (let appNavItem of appNavItems) {
    let availableAppNavItem = undefined;
    // only show if valid
    if (isAppTaskAvailable(appNavItem, entitlements)) {
      if (appNavItem.children) {
        appNavItem.children = getAvailableTilesRecursive(
          appNavItem.children,
          entitlements
        );
      }
      // hide unless no children are configurable or it has non-zero children
      if (!appNavItem.children || appNavItem.children.length !== 0) {
        availableAppNavItem = appNavItem;
      }
    }
    if (availableAppNavItem) {
      availableAppNavItems.push(availableAppNavItem);
    }
  }
  return availableAppNavItems;
};

export const getAvailableAppsForWaffle = (
  skipCheck: boolean,
  entitlements: Entitlement[] | null | undefined
) => {
  if (!entitlements) return [];
  let myAppsNavItems = getAllAppNavItems();
  if (skipCheck) {
    return myAppsNavItems;
  }
  let appNavItems = getAvailableTilesRecursive(myAppsNavItems, entitlements);
  return appNavItems;
};

export const getAvailableDataForWaffle = (
  skipCheck: boolean,
  entitlements: Entitlement[] | null | undefined
) => {
  if (!entitlements) return [];
  let myDataNavItems = getMyDataNavs();
  if (skipCheck) {
    return myDataNavItems;
  }
  let dataNavItems = getAvailableTilesRecursive(myDataNavItems, entitlements);
  return dataNavItems;
};

// hardcoded mappings

export const METRIC_TO_APP_NAV_MAPPING = {
  [AppNavItemIds.AR]: [695, 697, 699],
  [AppNavItemIds.STARREADING]: [865, 867, 869, 871],
  [AppNavItemIds.STARMATH]: [841, 843, 845, 847],
  [AppNavItemIds.STAREARLYLITERACY]: [829, 831, 833],
  // Spanish products
  [AppNavItemIds.STARREADINGSPANISH]: [873, 875, 877, 903],
  [AppNavItemIds.STARMATHSPANISH]: [849, 851, 853, 905],
  [AppNavItemIds.STAREARLYLITERACYSPANISH]: [835, 837, 839],
  // Other products
  [AppNavItemIds.STARCBMREADING]: [741, 747],
  [AppNavItemIds.STARCBMMATH]: [743, 749],
  [AppNavItemIds.STARCBMLECTURA]: [745, 751],
  [AppNavItemIds.STARPHONICS]: [891, 893],
  [AppNavItemIds.MYON]: [701, 703, 705],
  [AppNavItemIds.FRECKLE]: [
    673, 675, 677, 679, 681, 683, 685, 687, 717, 719, 721, 723,
  ],
  [AppNavItemIds.LALILO]: [765, 767, 769],
  [AppNavItemIds.RENU]: [],
  [AppNavItemIds.REPORTS]: [],
  [AppNavItemIds.SAEBRS]: [895, 897, 899, 901],
} as { [key in AppNavItemIds]?: number[] };

const PANEL_TO_APP_NAV_MAPPING = {
  "Accelerated Reader": AppNavItemIds.AR,
  Reading: AppNavItemIds.STARREADING,
  Math: AppNavItemIds.STARMATH,
  "Reading Spanish": AppNavItemIds.STARREADINGSPANISH,
  "Math Spanish": AppNavItemIds.STARMATHSPANISH,
  "Early Literacy Spanish": AppNavItemIds.STAREARLYLITERACYSPANISH,
  "Early Literacy": AppNavItemIds.STAREARLYLITERACY,
  myON: AppNavItemIds.MYON,
  Freckle: AppNavItemIds.FRECKLE,
  Lalilo: AppNavItemIds.LALILO,
  "Star Phonics": AppNavItemIds.STARPHONICS,
  "Star CBM": [
    AppNavItemIds.STARCBMREADING,
    AppNavItemIds.STARCBMMATH,
    AppNavItemIds.STARCBMLECTURA,
  ],
  SAEBRS: AppNavItemIds.SAEBRS,
}

// end hardcoded mappings

const hasAppNavId = (item: AppNavItem, targetId: AppNavItemIds | AppNavItemIds[]): boolean => {
  if (Array.isArray(targetId)) {
    return targetId.some(id => hasAppNavId(item, id));
  }
  
  return (
    item.id === targetId || 
    (item.children?.some(child => 
      child.id === targetId || 
      (child.children?.some(grandChild => hasAppNavId(grandChild, targetId)) ?? false)
    ) ?? false)
  );
};

export const checkIfPanelIsInEntitlements = (
  panel: CustomerPanelsEntity | Panel,
  appNavItems: AppNavItem[] | null | undefined
) => {
  if (!appNavItems?.length) return false;
  const appNavId = PANEL_TO_APP_NAV_MAPPING[panel.title as keyof typeof PANEL_TO_APP_NAV_MAPPING];
  if (!appNavId) return false;
  
  return appNavItems.some(appNavItem => hasAppNavId(appNavItem, appNavId));
};

export const isTileInEntitlements = (
  metricId: number,
  appNavItems: AppNavItem[] | null | undefined
) => {
  if (!metricId || !appNavItems?.length) return false;
  const appNavItemId = Object.keys(METRIC_TO_APP_NAV_MAPPING).find(key => 
    METRIC_TO_APP_NAV_MAPPING[key as AppNavItemIds]?.includes(metricId)
  ) as AppNavItemIds | undefined;
  
  if (!appNavItemId) return false;
  return appNavItems.some(appNavItem => hasAppNavId(appNavItem, appNavItemId));
};

export const filterTilesBasedOnEntitlements = (
  tiles: TileEntity[],
  appNavItems: AppNavItem[] | null | undefined
) => {
  if (!appNavItems || appNavItems.length === 0) return [];
  return tiles.filter((tile: TileEntity) => {
    return isTileInEntitlements(tile.KPI, appNavItems);
  });
};

export const filterMetricIdsBasedOnEntitlements = (
  metricIds: number[],
  appNavItems: AppNavItem[] | null | undefined
) => {
  if (!appNavItems || appNavItems.length === 0) return [];
  return metricIds.filter((metricId: number) => {
    return isTileInEntitlements(metricId, appNavItems);
  });
};


export const filterMetricsBasedOnEntitlementsVisibilitySettings = (
  metrics: Metric[],
  appNavItems: AppNavItem[] | null | undefined
) => {
  if (!appNavItems || appNavItems.length === 0) return [];
  const metricsInEntitlements = metrics.filter((metric: Metric) => {
    return isTileInEntitlements(metric.kpi_id, appNavItems);
  });
  return metricsInEntitlements;
};

export const filterStudentPanelsBasedOnEntitlements = (
  panels: Panel[],
  columns: Column[],
  appNavItems: AppNavItem[] | null | undefined
) => {
  if (!appNavItems || appNavItems.length === 0) return [];
  return panels
    .map((panel: Panel) => {
      const isPanelInEntitlement = checkIfPanelIsInEntitlements(
        panel,
        appNavItems
      );
      if (!isPanelInEntitlement) return null;
      const studentKpiIds = panel.studentKpiIds?.filter(
        (studentKpiId: number) => {
          const column = find(columns, { id: studentKpiId });
          return isTileInEntitlements(column.parentId, appNavItems);
        }
      );
      if (studentKpiIds?.length === 0) return null;
      return {
        ...panel,
        studentKpiIds: studentKpiIds,
        studentKPICount: studentKpiIds?.length,
      };
    })
    .filter(Boolean) as Panel[];
};
