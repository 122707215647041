import React from "react";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

interface BookProps {
  cover_url: string;
  title: string;
  author: string;
  tags: string[];
}

interface BookContainerProps {
  books: BookProps[];
}

const Container = styled("div")({
  display: "flex",
  width: "100%",
  flexDirection: "column",
});

const BookItemContainer = styled("div")({
  display: "flex",
  marginTop: "var(--std-margin-xs)",
  flexWrap: "wrap",
  justifyContent: "space-around",
});

const BookItem = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minWidth: "110px",
  maxWidth: "110px",
  margin: "var(--std-margin-xs)",
});

const BookCover = styled("img")({
  height: "84.2px",
  marginBottom: "var(--std-margin-xxs)",
});

const BookTitle = styled("p")({
  fontSize: "var(--std-font-xs)",
  fontWeight: "bold",
  margin: "0",
  textAlign: "center",
});

const NFSpan = styled("span")({
  color: "var(--red)",
  fontSize: "9px",
  fontWeight: 700,
});

const BookAuthor = styled("p")({
  fontSize: "9px",
  margin: "0",
  textAlign: "center",
});

const FewBooks = styled("p")({
  fontStyle: "italic",
  margin: "var(--std-margin-xs)",
  textAlign: "center",
  fontSize: "var(--std-font-xs)",
});

const BookContainer: React.FC<BookContainerProps> = ({ books }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <BookItemContainer>
        {books.slice(0, 10).map((book, index) => (
          <BookItem key={index}>
            <BookCover src={book.cover_url} alt={book.title} />
            <BookTitle>
              {book.title}{" "}
              {book.tags.includes("nonfiction") && <NFSpan>NF</NFSpan>}
            </BookTitle>
            <BookAuthor>{book.author}</BookAuthor>
          </BookItem>
        ))}
      </BookItemContainer>
      {books.length < 5 && (
        <FewBooks>{t("TEXT_WKAR_MINIMUM_THRESHOLD_ADDITIONAL")}</FewBooks>
      )}
    </Container>
  );
};

export default BookContainer;
