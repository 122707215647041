import { styled } from "@mui/material";

export const NavIconContainer = styled("button")(() => ({
  padding: 0,
  borderRadius: "50%",
  border: "none",
  background: "none",
  "&:hover": {
    backgroundColor: "var(--beige)",
    cursor: "pointer",
  },
  "&:focus": {
    outline: "none",
    boxShadow: "0 0 0 2px var(--blue)",
  },
}));

export const NavUserIconContainer = styled("button")(() => ({
  width: "46px",
  height: "46px",
  border: "2px solid transparent",
  padding: 0,
  borderRadius: "50%",
  backgroundColor: "var(--beige)",
  textAlign: "center",
  "&:hover": {
    border: "2px solid var(--red)",
    cursor: "pointer",
  },
  "&:focus": {
    outline: "none",
    boxShadow: "0 0 0 2px var(--blue)",
  },
  p: {
    fontSize: "var(--std-font-lg)",
    fontWeight: "bold",
    marginTop: "var(--std-margin-sm)",
    padding: 0,
  },
}));

export const NavIcon = styled("svg")(() => ({
  fill: "var(--grey600)",
  width: "48px",
  height: "48px",
  margin: "auto",
  display: "block",
  "&:hover path, &:hover rect": {
    fill: "var(--red) !important",
  },
}));
