import React from "react";
import { useTranslation } from "react-i18next";
import { PictureAsPdfOutlined } from "@mui/icons-material";
import { RAButton } from "../lib/button";
import { ClipLoader } from "react-spinners";
import { styled } from "@mui/system";
import { School } from "./StoriesWrapper";
import { customFetch } from "../Utils";

const ShareText = styled("div")({
  fontSize: "var(--std-font-sm)",
  color: "var(--tablegrey)",
  marginRight: "var(--std-margin-sm)",
});

const Container = styled("div")({
  display: "flex",
  alignItems: "center",
  alignSelf: "flex-end",
  marginBottom: "var(--std-margin-sm)",
  marginRight: "var(--std-margin-xlg)",
});

const StyledButton = styled(RAButton)({
  backgroundColor: "white",
  color: "var(--blue)",
  borderColor: "var(--blue)",
  height: "fit-content",
  "&:hover": {
    backgroundColor: "var(--blue)",
    color: "white",
    borderColor: "var(--blue)",
  },
});

interface DownloadWKARButtonProps {
  selectedSchool: School;
}

export const DownloadWKARButton = ({
  selectedSchool,
}: DownloadWKARButtonProps) => {
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = React.useState(false);

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      const endpoint = selectedSchool.id.includes("_district")
        ? "/stories/wkar/pdf"
        : `/stories/wkar/school/${selectedSchool.id}/pdf`;

      const response = await customFetch(endpoint);

      if (response.ok) {
        // get the name of the pdf from the response headers
        const contentDisposition = response.headers.get("Content-Disposition");
        const filenameMatch = contentDisposition?.match(/filename=(.*)/);
        const filename = filenameMatch ? filenameMatch[1] : "wykar_report.pdf";

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }
    } finally {
      setIsDownloading(false);
    }
  };

  const buttonContent = isDownloading ? (
    <>
      <ClipLoader size={20} color="var(--blue)" />
      <span style={{ marginLeft: "var(--std-margin-sm)" }}>
        {t("TEXT_WKAR_DOWNLOAD_REPORT")}
      </span>
    </>
  ) : (
    <>
      <PictureAsPdfOutlined sx={{ marginRight: "var(--std-margin-sm)" }} />
      {t("TEXT_WKAR_DOWNLOAD_REPORT")}
    </>
  );

  return (
    <Container>
      <ShareText>Share in meetings or to email </ShareText>
      <StyledButton
        variant="outlined"
        onClick={handleDownload}
        disabled={isDownloading}
      >
        {buttonContent}
      </StyledButton>
    </Container>
  );
};
