import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionSummary } from "@mui/material";
import { styled } from "@mui/system";
import WaffleIcon from "../WaffleIcon";
import { AppNavItem } from "../WaffleMenu";


export const AppLinkSelectorOption = ({
  appNavItem,
  navigateToLaunchApp,
  isDefaultExpanded,
}: {
  appNavItem: AppNavItem;
  navigateToLaunchApp: (appNavItem: AppNavItem) => void;
  isDefaultExpanded?: boolean;
}) => {
  const AccordionLink = styled("div")`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    margin: 0;
    color: var(--blue);
    cursor: pointer;
    font-size: var(--std-font-sm);

    &:hover {
      background: rgba(20, 110, 179, 0.05);
      text-decoration: underline;
    }
  `;
  const AccordionHeader = styled("div")`
    display: flex;
    flex-direction: column;
    font-size: var(--std-font-lg);
    font-weight: 700;
    color: var(--tablegrey);
  `;
  const AccordionDisplayName = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: left;
  `;

  const AccordionSubHeader = styled("div")`
    font-size: var(--std-font-sm);
    font-weight: 400;
    color: var(--grey600);
  `;

  const AccordionDivider = styled("hr")`
    height: 0;
    margin: 0;
    border: 0;
    border-top: 1px solid #e5e5e5;
  `;

  const AccordionSelectorSummary = styled(AccordionSummary)`
    padding: 0;
    margin: 0;
    .Mui-expanded {
      margin: 0;
    }
  `;

  const HeaderText = styled("div")`
    line-height: 40px;
    vertical-align: middle;
  `;

  const AccordionList = styled("ul")`
    list-style: none;
    margin: 0;
    padding: 0;
  `;

  return (
    <>
      <AccordionDivider />
      {appNavItem.children && (
        <Accordion
          style={{ boxShadow: "none", padding: "0 15px 0 25px" }}
          defaultExpanded={isDefaultExpanded}
          data-cy="app-link-selector-option-accordion"
        >
          <AccordionSelectorSummary
            expandIcon={isDefaultExpanded ? <></> : <ExpandMoreIcon />}
          >
            <AccordionHeader>
              <AccordionDisplayName>
                <WaffleIcon
                  imagePrefix={appNavItem.imagePrefix}
                  style={{
                    height: "40px",
                    marginRight: "var(--std-margin-xs)",
                  }}
                />
                <HeaderText>{appNavItem.displayName}</HeaderText>
              </AccordionDisplayName>
              <AccordionSubHeader>{appNavItem.subHeader}</AccordionSubHeader>
            </AccordionHeader>
          </AccordionSelectorSummary>
          <AccordionList>
            {appNavItem.children.map((child: any) => (
              <div key={child.displayName}>
                <AccordionLink
                  tabIndex={0}
                  onClick={() => navigateToLaunchApp(child)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigateToLaunchApp(child);
                    }
                  }}
                >
                  {child.displayName}
                </AccordionLink>
              </div>
            ))}
          </AccordionList>
          <div className="bottom-spacer"></div>
        </Accordion>
      )}
      {/* direct link if has no children */}
      {!appNavItem.children && (
        <Accordion
          style={{ boxShadow: "none", padding: "0 0 0 var(--std-padding-sm)" }}
          expanded={false}
          onClick={() => navigateToLaunchApp(appNavItem)}
          data-cy="app-link-selector-option-accordion"
        >
          <AccordionSummary>
            <AccordionHeader>
              <AccordionDisplayName>
                <WaffleIcon
                  imagePrefix={appNavItem.imagePrefix}
                  style={{
                    height: "40px",
                    marginRight: "var(--std-margin-xs)",
                  }}
                />
                <HeaderText>{appNavItem.displayName}</HeaderText>
              </AccordionDisplayName>
              <AccordionSubHeader>{appNavItem.subHeader}</AccordionSubHeader>
            </AccordionHeader>
          </AccordionSummary>
        </Accordion>
      )}
    </>
  );
};
