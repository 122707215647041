import React from "react";
import Select, { AriaOnFocus, GroupBase, StylesConfig } from "react-select";
import InputLabel from "@mui/material/InputLabel";
import { CheckboxOptionT } from "./Multiselect";

interface Data {
  label: string;
  value: string;
  options?: CheckboxOptionT[];
}

type SingleSelectProps = {
  id?: string;
  options: Data[];
  onSelectedChange: (selected: string) => void;
  selectOptionsText: string;
  selected?: string;
  disabled?: boolean;
  ariaLabel?: string;
  isClearable?: boolean;
};

// this single select handles two of our four select cases:
// 1. selecting a single value from a list
// 2. selecting a single value from a nested list of category/values

const SingleSelect: React.FC<SingleSelectProps> = ({
  id,
  options,
  onSelectedChange,
  selectOptionsText,
  selected,
  disabled = false,
  ariaLabel,
  isClearable = true,
}) => {

  const customStyles: StylesConfig<Data, false, GroupBase<Data>> = {
    control: (provided) => ({
      ...provided,
      width: 400,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      textDecoration: state.isFocused ? "underline" : "none",
    }),
  };

  // if our options have options, we're in a grouped single select
  let currentValue: Data | undefined;
  if (options.length > 0 && options[0].hasOwnProperty("options")) {
    for (let i = 0; i < options.length; i++) {
      const groupOptions = options[i].options!;
      currentValue = groupOptions.find((option) => option.value === selected);
      if (currentValue) break;
    }
  } else {
    currentValue = options.find((option) => option.value === selected);
  }

  const formatOptionLabel = (option: any) => (
    <div
      id={`option-${option.value}`}
      aria-label={`${option.category}: ${option.label}`}
    >
      {option.label}
    </div>
  );

  const onFocus: AriaOnFocus<any> = ({ focused, isDisabled }) => {
    return `You are currently focused on option ${focused.label} ${
      focused.category ? `in the ${focused.category} category` : ""
    }.`;
  };

  return (
    <Select
      id={id}
      key={selected}
      isDisabled={disabled}
      isClearable={isClearable}
      name={selectOptionsText}
      value={currentValue}
      options={options}
      placeholder={selectOptionsText}
      onChange={(selected) => {
        if (selected) {
          onSelectedChange(selected.value);
        } else {
          onSelectedChange("");
        }
      }}
      styles={customStyles}
      aria-label={ariaLabel}
      formatOptionLabel={formatOptionLabel}
      ariaLiveMessages={{
        onFocus,
      }}
    />
  );
};

export const SingleSelectInputLabel = ({
  htmlFor,
  children,
}: {
  htmlFor: string;
  children: React.ReactNode;
}) => {
  return (
    <InputLabel htmlFor={htmlFor} style={{ color: "var(--grey-600)" }}>
      {children}
    </InputLabel>
  );
};

export default SingleSelect;
