import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import SingleSelect, { SingleSelectInputLabel } from "../SingleSelect";
import { School, Grade } from "./StoriesWrapper";

interface GradeDropdownProps {
  selectedSchool: School | undefined;
  selectedGrade: Grade | null;
  selectGrade: (grade: Grade) => void;
}

interface SingleSelectOption {
  label: string;
  value: string;
}

const GradeDropdown: React.FC<GradeDropdownProps> = ({
  selectedSchool,
  selectedGrade,
  selectGrade,
}) => {
  const [options, setOptions] = useState<SingleSelectOption[]>([]);

  useEffect(() => {
    if (selectedSchool) {
      // Transform the grades array into the options format
      const options = selectedSchool.grades.map((grade) => ({
        label: grade.grade_level_long,
        value: grade.grade_level_numeric.toString(),
      }));

      setOptions(options);
    }
  }, [selectedSchool]);

  const handleSelectedGrade = (selected: string) => {
    const grade = selectedSchool?.grades.find(
      (grade) => grade.grade_level_numeric.toString() === selected
    );
    grade && selectGrade(grade);
  };

  return (
    <div
      style={{
        margin: "var(--std-margin-lg) 0 var(--std-margin-sm) 0",
        width: "420px",
      }}
    >
      <SingleSelectInputLabel htmlFor="grade-select">
        Select Grade
      </SingleSelectInputLabel>
      <SingleSelect
        id="grade-select"
        selected={
          selectedGrade ? selectedGrade.grade_level_numeric.toString() : ""
        }
        options={options ? options : []}
        onSelectedChange={handleSelectedGrade}
        disabled={false}
        selectOptionsText="Select grade"
        isClearable={false}
      />
    </div>
  );
};

export default GradeDropdown;
