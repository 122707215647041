import React, { useState, useEffect, useContext } from "react";
import Stories from "./Stories";
import { UserContext } from "../UserContext";
import { School as UserInfoSchool } from "../../interfaces/UserInfoJson";
import { customFetch } from "../Utils";

export interface Grade {
  grade_level: string;
  grade_level_numeric: number;
  grade_level_long: string;
}

export interface School {
  id: string;
  name: string;
  products: string[];
  grades: Grade[];
}

interface District {
  id: string;
  name: string;
  products: string[];
  grades: Grade[];
}

export interface CustomerMetadata {
  district: District;
  schools: {
    [id: string]: School;
  };
}

interface SchoolStats {
  books_read_count: number;
  words_read_count: number;
  nonfiction_percent: number;
}

export interface OverallStats {
  district: SchoolStats;
  schools: {
    [id: string]: SchoolStats;
  };
}

export interface Book {
  cover_url: string;
  title: string;
  author: string;
  tags: string[];
}

export interface GradeLevelData {
  students_count: number;
  schools_count: number;
  nonfiction_percent: number;
  books_read: number;
  books_read_avg: number;
  words_read_avg: number;
  atos_level_avg: number;
  top_authors: string[];
  ar_books: Book[];
  myon_books: Book[];
}

export interface GradeLevelTotals {
  total_books: number;
  total_students: number;
}

export interface GradeLevelDataResponse {
  grades: { [grade: string]: GradeLevelData };
  totals: GradeLevelTotals;
}

export interface GradeBandData {
  students_count: number;
  schools_count: number;
  books_read_count: number;
  nonfiction_percent: number;
}

export interface GradeBandResponse {
  [key: string]: GradeBandData;
}

const StoriesWrapper: React.FC = () => {
  const [customerMetadata, setCustomerMetadata] =
    useState<CustomerMetadata | null>(null);
  const [overallStats, setOverallStats] = useState<OverallStats | null>(null);
  const [selectedSchool, setSelectedSchool] = useState<School | undefined>(
    customerMetadata?.schools
      ? Object.values(customerMetadata.schools)[0]
      : undefined
  );
  const [gradeLevelData, setGradeLevelData] =
    useState<GradeLevelDataResponse | null>(null);
  const [selectedGrade, setSelectedGrade] = useState<Grade | undefined>(
    customerMetadata?.schools &&
      Object.values(customerMetadata.schools)[0].grades?.length
      ? Object.values(customerMetadata.schools)[0].grades[0]
      : undefined
  );
  const [gradeBandData, setGradeBandData] = useState<GradeBandResponse | null>(
    null
  );

  const [isLoadingCustomerMetadata, setIsLoadingCustomerMetadata] =
    useState(true);
  const [isLoadingOverallStats, setIsLoadingOverallStats] = useState(true);
  const [isLoadingGradeLevelData, setIsLoadingGradeLevelData] = useState(true);
  const [isLoadingGradeBandData, setIsLoadingGradeBandData] = useState(true);

  const userContext = React.useContext(UserContext);
  const userInfoSchools: UserInfoSchool[] | null | undefined =
    userContext?.userInfo?.schools;

  // watch for changes to selectedSchool and choose the first grade for the selected school
  useEffect(() => {
    if (selectedSchool) {
      // reset the grade level and grade band data
      setGradeLevelData(null);
      setGradeBandData(null);
      setIsLoadingGradeBandData(true);
      setIsLoadingGradeLevelData(true);

      setSelectedGrade(selectedSchool.grades[0]);
      // fetch grade level data by school or district (for all grades)
      const fetchGradeData = async () => {
        if (selectedSchool) {
          try {
            let gradeLevelResponse;
            let gradeBandResponse;
            if (selectedSchool.id.includes("_district")) {
              gradeLevelResponse = await customFetch(
                "/stories/wkar/by_grade_level"
              );
              gradeBandResponse = await customFetch(
                "/stories/wkar/by_grade_band"
              );
            } else {
              gradeLevelResponse = await customFetch(
                `/stories/wkar/school/${selectedSchool.id}/by_grade_level`
              );
              gradeBandResponse = await customFetch(
                `/stories/wkar/school/${selectedSchool.id}/by_grade_band`
              );
            }

            if (!gradeLevelResponse.ok) {
              setIsLoadingGradeLevelData(false);
              throw new Error("Network response was not ok");
            }

            if (!gradeBandResponse.ok) {
              setIsLoadingGradeBandData(false);
              throw new Error("Network response was not ok");
            }

            const gradeLevelData = await gradeLevelResponse.json();
            setGradeLevelData(gradeLevelData);
            setIsLoadingGradeLevelData(false);
            const gradeBandData = await gradeBandResponse.json();
            setGradeBandData(gradeBandData);
            setIsLoadingGradeBandData(false);
          } catch (error) {
            setIsLoadingGradeBandData(false);
            setIsLoadingGradeLevelData(false);
            console.error("Failed to fetch grade level data:", error);
          }
        }
      };

      fetchGradeData();
    }
  }, [selectedSchool]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const metadataResponse = await customFetch("/stories/wkar/metadata");
        if (!metadataResponse.ok) {
          setIsLoadingCustomerMetadata(false);
          throw new Error("Network response was not ok");
        }
        const metadata = await metadataResponse.json();

        const statsResponse = await customFetch("/stories/wkar/overall");
        if (!statsResponse.ok) {
          setIsLoadingOverallStats(false);
          throw new Error("Network response was not ok");
        }
        const stats = await statsResponse.json();
        // we filter out any schools that aren't in /userinfo/schools and sort them by name
        const schoolsArray: School[] = Object.values(metadata.schools);
        let schoolsObject: { [id: string]: School };

        const userInfoSchoolsArray = userInfoSchools
          ? Object.values(userInfoSchools)
          : [];

        const filteredAndSortedSchools = schoolsArray
          .filter((school: School) =>
            userInfoSchoolsArray?.some(
              (userInfoSchool: UserInfoSchool) =>
                userInfoSchool.systemSchoolId === school.id
            )
          )
          .sort((a: School, b: School) => a.name.localeCompare(b.name));

        schoolsObject = filteredAndSortedSchools.reduce((acc, school) => {
          acc[school.id] = school;
          return acc;
        }, {} as { [id: string]: School });

        let initialSchool: School | undefined;

        if (metadata.district) {
          const districtOverall: School = {
            id: `${metadata.district.id}_district`,
            name: `${metadata.district.name} Overall`,
            products: metadata.district.products,
            grades: metadata.district.grades,
          };
          // if we're in a district, we add the district "school" to the list of schools
          schoolsObject = {
            [`${metadata.district.id}_district`]: districtOverall,
            ...schoolsObject,
          };

          initialSchool = districtOverall;
        } else {
          initialSchool = Object.values(schoolsObject)[0] as School;
        }

        const updatedMetadata = { ...metadata, schools: schoolsObject };
        setCustomerMetadata(updatedMetadata);
        setOverallStats(stats);
        setSelectedSchool(initialSchool);
        setIsLoadingCustomerMetadata(false);
        setIsLoadingOverallStats(false);
      } catch (error) {
        setIsLoadingCustomerMetadata(false);
        setIsLoadingOverallStats(false);
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Stories
      customerMetadata={customerMetadata}
      overallStats={overallStats}
      selectedSchool={selectedSchool}
      selectSchool={setSelectedSchool}
      selectedGrade={selectedGrade}
      selectGrade={setSelectedGrade}
      gradeLevelData={gradeLevelData}
      gradeBandData={gradeBandData}
      isLoadingCustomerMetadata={isLoadingCustomerMetadata}
      isLoadingOverallStats={isLoadingOverallStats}
      isLoadingGradeLevelData={isLoadingGradeLevelData}
      isLoadingGradeBandData={isLoadingGradeBandData}
    />
  );
};

export default StoriesWrapper;
