import "./App.css";
import React, { useEffect, useState } from "react";
import AppRouterWrapper from "./AppRouterWrapper";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CustomQueryClientContext } from "./components/CustomQueryClientContext";
import Cookies from "js-cookie";
import { IdleStates, KeepAliveModal } from "./components/KeepAlive/KeepAlive";
import { useIdleTimer } from "react-idle-timer";
import { doLogout } from "./components/Utils";
import {
  AuthCookieContext,
  AuthCookieContextType,
  getAuthCookieData,
} from "./components/AuthCookieContext";
import Link from "@mui/material/Link";
import { customFetch } from "./components/Utils";

function App() {
  const [authCookieContextData, setAuthCookieContextData] =
    useState<AuthCookieContextType>();
  const [idleState, setIdleState] = useState<IdleStates>("active");

  const onIdle = () => {
    doLogout();
  };

  const onPrompt = () => {
    setIdleState("prompted");
  };

  const onActive = () => {
    setIdleState("active");
  };

  useEffect(() => {
    const token = Cookies.get("SchoolzillaAuthToken");
    if (token) {
      const authCookieData = getAuthCookieData(token);

      if (authCookieData?.keepaliveuri && authCookieData.timeoutminutes) {
        // The amount of time a user has to respond to being idle is in timetorespondminutes, or 5 minutes if not specified.
        let timetorespondminutes = authCookieData.timetorespondminutes || 5;
        if (
          !authCookieData.timetorespondminutes &&
          authCookieData.timeoutminutes < 5
        ) {
          // If timeoutminutes is less than 5 minutes, we replace timetorespondminutes with half of the value of timeout minutes.
          timetorespondminutes = authCookieData.timeoutminutes / 2;
        }

        setAuthCookieContextData({
          ...authCookieData,
          timeoutminutes: authCookieData.timeoutminutes * 60 * 1000,
          timetorespondminutes:
            (authCookieData.timeoutminutes - timetorespondminutes) * 60 * 1000,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (authCookieContextData?.keepaliveuri) {
      const fetchKeepAlive = () => {
        customFetch(authCookieContextData.keepaliveuri!)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.text();
          })
          .then((data) => {})
          .catch((error) => {
            console.error("Keep alive request failed:", error);
          });
      };

      // Immediately invoke fetchKeepAlive when keepAliveUri is set
      fetchKeepAlive();

      // Set up the interval to invoke fetchKeepAlive every minute
      const intervalId = setInterval(fetchKeepAlive, 60000);

      // Clean up the interval on component unmount or when keepAliveUri changes
      return () => clearInterval(intervalId);
    }
  }, [authCookieContextData]);

  const idleTimer = useIdleTimer({
    onIdle,
    onPrompt,
    onActive,
    crossTab: true,
    timeout: authCookieContextData?.timeoutminutes,
    promptBeforeIdle: authCookieContextData?.timetorespondminutes,
    // if timeoutminutes is null or undefined, we will not log the user out for being idle
    disabled:
      authCookieContextData?.timeoutminutes === null ||
      authCookieContextData?.timeoutminutes === undefined,
    syncTimers: 500,
  });

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: process.env.REACT_APP_REACT_QUERY_CACHE_TIME
          ? parseInt(process.env.REACT_APP_REACT_QUERY_CACHE_TIME)
          : 0,
        gcTime: process.env.REACT_APP_REACT_QUERY_CACHE_TIME
          ? parseInt(process.env.REACT_APP_REACT_QUERY_CACHE_TIME)
          : 0,
      },
    },
  });

  return (
    <div className="App">
      <AuthCookieContext.Provider value={{ ...authCookieContextData }}>
        <QueryClientProvider client={queryClient}>
          <CustomQueryClientContext.Provider value={{ client: queryClient }}>
            <KeepAliveModal
              idleState={idleState}
              setIdleState={setIdleState}
              activate={idleTimer.activate}
              logout={doLogout}
            ></KeepAliveModal>
            <BrowserRouter>
              <AppRouterWrapper />
            </BrowserRouter>
            <footer
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "var(--std-margin-md) var(--std-margin-xxs)",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "var(--std-font-xlg)",
                  color: "var(--darkgrey)",
                }}
              >
                Accelerating Learning for All
              </p>
              <p style={{ margin: 0 }}>
                {`© 2013-${new Date().getFullYear()} Renaissance Learning, Inc. All rights reserved.`}{" "}
                <Link
                  href="https://status.renaissance.com"
                  target="_blank"
                  style={{
                    color: "var(--black)",
                    textDecorationColor: "var(--black)",
                  }}
                >
                  System Status
                </Link>
              </p>
            </footer>
          </CustomQueryClientContext.Provider>
        </QueryClientProvider>
      </AuthCookieContext.Provider>
    </div>
  );
}

export default App;
