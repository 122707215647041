import React, { useState, useEffect, useContext } from "react";
import { Switch, Modal, Box } from "@mui/material";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { styled } from "@mui/material/styles";
import confetti from "canvas-confetti";
import OptInModal from "./OptInModal";
import OptOutModal from "./OptOutModal";
import PendoTrack from "../Pendo/Pendo";
import { AuthCookieContext } from "../AuthCookieContext";
import { getRgpBaseUri } from "../Utils";
import { TitleTooltip } from "../TitleTooltip";
import { UserContext } from "../UserContext";
import { customFetch } from "../Utils";
import "./Wobble.css";

const OptInOptOut = () => {
  const ldClient = useLDClient();
  const { frontendConfettiNudge, frontendOptInToggleLanguage } = useFlags();
  const [toggleState, setToggleState] = useState(false);
  const [openOptInModal, setOpenOptInModal] = useState(false);
  const [openOptOutModal, setOpenOptOutModal] = useState(false);
  const [rgpBaseUri, setRgpBaseUri] = useState<string | undefined>();
  const [animate, setAnimate] = useState(true); // State to control animation
  const userContext = useContext(UserContext);
  const authCookieContext = useContext(AuthCookieContext);
  useEffect(() => {
    if (authCookieContext?.homeuri)
      setRgpBaseUri(getRgpBaseUri(authCookieContext.homeuri));
  }, []);
  useEffect(() => {
    // Remove the animation class after the animation completes
    const timer = setTimeout(() => setAnimate(false), 2000);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (userContext?.userSettings?.homepageOptIn) {
      setToggleState(true);
    }
  });

  const handleToggle = () => {
    if (!toggleState) {
      ldClient?.track("opt-in-toggle-click");
      PendoTrack({
        eventType: `RA.optin.toggleClick`,
        eventData: { hadConfetti: frontendConfettiNudge },
      });
      if (frontendConfettiNudge) {
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.27, x: 0.1 },
          zIndex: 2147483647,
          colors: ["#cf3a4e", "#FFFFF", "#f1efe3"],
        });
        setTimeout(() => {
          setOpenOptInModal(true);
          setOpenOptOutModal(false);
        }, 1000); // Delay for 1 second to allow confetti to finish
      } else {
        setOpenOptInModal(true);
        setOpenOptOutModal(false);
      }
    } else {
      setOpenOptOutModal(true);
      setOpenOptInModal(false);
      PendoTrack({
        eventType: `RA.optout.toggleClick`,
        eventData: { hadConfetti: frontendConfettiNudge },
      });
    }
    setToggleState(!toggleState);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleToggle();
    }
  };

  const handleClose = (feedback?: string) => {
    PendoTrack({
      eventType: `RA.optinoptout.cancel`,
      eventData: { feedback: feedback, hadConfetti: frontendConfettiNudge },
    });
    setOpenOptInModal(false);
    setOpenOptOutModal(false);
    setToggleState(!toggleState);
  };

  const handleOptIn = () => {
    ldClient?.track("opt-in-modal-click");
    // track an opt in on our end with a PATCH to /usersettings
    customFetch("/usersettings", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        homepageOptIn: true,
      }),
    });
    PendoTrack({
      eventType: `RA.optin.click`,
      eventData: { hadConfetti: frontendConfettiNudge },
    });
    if (frontendConfettiNudge) {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        zIndex: 2147483647,
        colors: ["#cf3a4e", "#FFFFF", "#f1efe3"],
      });
      setTimeout(() => {
        window.location.href = `${rgpBaseUri}/educatorportal/api/next/optin-leader`;
      }, 2000); // Delay for 2 seconds to allow confetti to finish
    } else {
      window.location.href = `${rgpBaseUri}/educatorportal/api/next/optin-leader`;
    }
  };

  const handleOptOut = (feedback?: string) => {
    // track an opt out on our end with a PATCH to /usersettings
    customFetch("/usersettings", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        homepageOptIn: false,
      }),
    });
    PendoTrack({
      eventType: `RA.optout.click`,
      eventData: { feedback: feedback },
    });
    window.location.href = `${rgpBaseUri}/educatorportal/api/next/optout-leader`;
  };

  const OnOffSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      color: "#0B7D38",
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
      color: "var(--white)",
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#0B7D38 !important",
      opacity: "1 !important",
    },
  }));

  const SwitchLabel = styled("label")({
    color: "var(--tablegrey)",
    fontSize: "var(--std-font-xs)",
    fontStyle: "normal",
    fontWeight: 400,
  });

  const SwitchContainer = styled("div")({
    display: "flex",
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    margin: "var(--std-margin-sm) 0",
  });

  return (
    <SwitchContainer data-testid="opt-in-opt-out">
      <SwitchLabel htmlFor="homepage-toggle">
        {frontendOptInToggleLanguage
          ? "Try Renaissance Next as my new Home page"
          : "Try out this page as my new home page"}
        <TitleTooltip
          title="Why try this?"
          definition="You can always switch back. But we think you'll like the streamlined navigation with every Home link returning back to this data view"
          source=""
          size="small"
        ></TitleTooltip>
      </SwitchLabel>
      <OnOffSwitch
        id="homepage-toggle"
        checked={toggleState}
        onChange={handleToggle}
        onKeyDown={handleKeyDown}
        color="primary"
        name="toggle"
        as={Switch}
        role="switch"
        inputProps={{ "aria-label": "toggle" }}
        data-cy="homepage-toggle"
        className={`toggle ${
          animate && frontendConfettiNudge && !toggleState ? "wobble" : ""
        } ${toggleState ? "toggle-on" : "toggle-off"}`}
      />
      <Modal
        open={openOptInModal}
        onClose={() => handleClose}
        aria-labelledby="Opt In Modal"
        aria-describedby="Opt In to the new Renaissance Next home page"
      >
        <OptInModal handleOptIn={handleOptIn} handleClose={handleClose} />
      </Modal>
      <Modal
        open={openOptOutModal}
        onClose={() => handleClose}
        aria-labelledby="Opt Out Modal"
        aria-describedby="Opt Out of the new Renaissance Next home page and back to the legacy home page"
      >
        <OptOutModal handleOptOut={handleOptOut} handleClose={handleClose} />
      </Modal>
    </SwitchContainer>
  );
};

export default OptInOptOut;
