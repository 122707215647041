import React, { useEffect, useState } from "react";

import { School } from "./Demographics";
import { DistributionEntity } from "../interfaces/TableJson";
import { CategoryChartData } from "../interfaces/TileJson";
import { useSearchParams } from "react-router-dom";
import { TimePeriodLabelsEntity } from "../interfaces/TileJson";
import { ProfileJson } from "../interfaces/ProfileJson";

declare const window: any;

interface CategoryOption {
  category: string;
  option: string;
}

interface FieldValues {
  field: string;
  values: string[];
}

interface DictionaryEntry {
  filterByCurrentlyEnrolled: boolean;
}

interface Dictionary {
  [key: string]: DictionaryEntry;
}

export function transformCategoriesToFields(
  categories: CategoryOption[]
): FieldValues[] {
  const fieldsMap: { [key: string]: string[] } = {};
  for (const { category, option } of categories) {
    if (fieldsMap[category]) {
      fieldsMap[category].push(option);
    } else {
      fieldsMap[category] = [option];
    }
  }
  return Object.entries(fieldsMap).map(([field, values]) => ({
    field,
    values,
  }));
}

export function isFilterByCurrentlyEnrolled(
  fields: { field: string; values: string[] }[],
  field: string,
  dictionary: Dictionary
): boolean {
  const fieldsToCheck = fields.map((f) => f.field).concat(field);
  return fieldsToCheck.some((f) => {
    const entry = dictionary[f];
    return entry !== undefined && entry.filterByCurrentlyEnrolled;
  });
}

export function findOfficialSchoolName(
  schools: School[],
  systemSchoolId?: string
) {
  if (!systemSchoolId) return null;
  const school = schools.find(
    (school) => school.systemSchoolId === systemSchoolId
  );
  return school ? school.officialSchoolName : null;
}

export function findSystemSchoolId(
  schools: School[],
  officialSchoolName?: string
) {
  if (!officialSchoolName) return null;
  const school = schools.find(
    (school) => school.officialSchoolName === officialSchoolName
  );
  return school ? school.systemSchoolId : null;
}

export function sentenceCase(text: string) {
  return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
}

const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
};

export function useSearchParamKeys(
  paramKeys: string[]
): [string[], (value: string[]) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValues = paramKeys.map(
    (paramKey) => searchParams.get(paramKey) || ""
  );

  const setAllParamValues = (values: string[]) => {
    setSearchParams((searchParams) => {
      paramKeys.forEach((paramKey, index) => {
        const value = values[index];
        if (!value || value === "") {
          searchParams.delete(paramKey);
        } else {
          searchParams.set(paramKey, value);
        }
      });
      return searchParams;
    });
  };
  return [paramValues, setAllParamValues];
}

export const useImportScript = (resourceUrl: string) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = resourceUrl;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl]);
};

export default useCheckMobileScreen;

export const formatMetric = (
  metricY: number,
  roundTo: number | undefined,
  alpha?: string
) => {
  if (alpha) return alpha;
  const roundedValue = parseFloat(
    metricY.toFixed(roundTo !== undefined ? roundTo : 0)
  );
  return roundedValue.toLocaleString();
};

export const getPseudoUpIsGood = (
  data: DistributionEntity[] | CategoryChartData[] | null | undefined
) => {
  const sortedData = data?.sort((a: any, b: any) => a.order - b.order);

  // we look at the last bar's performance level max - the first bar's performance level max to see if up is good or not
  // (we can't use the metric's upIsGood because it isn't stored for the student metric, and this causes weird issues for Attendance/Chronic Absence)
  if (sortedData && sortedData.length > 1)
    return (
      sortedData[sortedData.length - 1].performanceLevelMax -
        sortedData[0].performanceLevelMax >
      0
    );
  return true;
};

export const getAdjustment = (roundTo: number | undefined) => {
  // Set adjustment to make PL max exclusive. For integers, adjustment = 1
  // For roundTo = 1 adjustment = 0.1
  // For roundTo = 2 adjustment = 0.01
  if (roundTo && roundTo > 0) {
    return 1 / 10 ** roundTo;
  } else {
    return 1;
  }
};

export const getSelectedTimePeriods = (
  timePeriods: TimePeriodLabelsEntity[]
) => {
  return timePeriods
    .reduce((acc, timePeriod) => {
      let selectedLabels = timePeriod.labels.filter((label) => label.selected);
      return [...acc, ...selectedLabels];
    }, [] as { name: string; selected: boolean; value: string }[])
    .map((label) => label.value);
};

export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function getPanelIdsToHide(kpiId: number, res: ProfileJson) {
  const panelIdsToHide = res?.customerFramework?.customerPanels
    ?.filter((panel) => !panel.tiles.find((tile) => tile.KPI === kpiId))
    .map((panel) => panel.id);
  return panelIdsToHide?.join(",");
}

export interface JwtPayload {
  homeuri?: string;
}

export function getRgpBaseUri(homeuri: string): string | undefined {
  return homeuri.substring(0, homeuri.indexOf("/", 8));
}

export async function doLogout() {
  try {
    const response = await customFetch("/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        credentials: "include",
      },
    });

    if (response.ok) {
      const data = await response.json();
      const redirectUrl = data.redirect_to;
      window.location.href = redirectUrl;
    } else {
      console.error("Logout failed:", response.statusText);
      // if logout fails for some reason send them to a sensible default page (they're probably logged out anyway)
      window.location.href =
        process.env.REACT_APP_DEFAULT_HOME_URI ||
        "https://schools.renaissance.com/";
    }
  } catch (error) {
    console.error("Error during logout:", error);
    // if logout fails for some reason send them to a sensible default page (they're probably logged out anyway)
    window.location.href =
      process.env.REACT_APP_DEFAULT_HOME_URI ||
      "https://schools.renaissance.com/";
  }
}

export const customFetch = async (
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> => {
  try {
    const response = await fetch(input, init);
    if (response.status === 401) {
      doLogout();
    }
    return response;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const openLiveChat = (rgpTenantId: string | undefined) => {
  const windowArgs =
    "width=484,height=620,resizable,scrollbars=yes,status=0,toolbar=0,menubar=0,location=0";

  if (typeof window.GetLiveChatHrefWithParameters === "function") {
    window.open(
      window.GetLiveChatHrefWithParameters("Schoolzilla", rgpTenantId, ""),
      "",
      windowArgs
    );
  } else {
    console.error("GetLiveChatHrefWithParameters is not defined");
  }
};
